import React from "react"
import styled from "styled-components"
import {
  headlineThree,
  standardWrapper,
  bodyCopy,
  colors,
  fontSizer,
} from "../../Utilities"

const OrderConfirm = ({ successData }) => {
  const { sanitizedOrder } = successData
  return (
    <OrderConfirmStyled>
      <div className="wrapper">
        <div className="receipt">
          <div className="successHeader">
            <h2>Drift Out West Fly Fishing</h2>
            <p>Thank you for your order</p>
            <p>Order no# {sanitizedOrder.id}</p>
            <p>
              A Copy of your receipt has been sent to: {sanitizedOrder.email}
            </p>
          </div>
          <div className="successDelivery">
            <h3>Delivery for</h3>
            <p>
              {sanitizedOrder.first_name} {sanitizedOrder.last_name}
            </p>
            <h3>Delivery Address</h3>
            <p>
              <span>{sanitizedOrder.street_address}</span>
            </p>
            <p>
              <span>{sanitizedOrder.city}</span>,{" "}
              <span>{sanitizedOrder.postal_code}</span>,{" "}
              <span>{sanitizedOrder.province}, </span>
              <span>{sanitizedOrder.country}</span>
            </p>
          </div>
          <div className="successDetails">
            <h3>Order Summary</h3>
            {sanitizedOrder.cart_details.map((product) => {
              return (
                <div className="successDetails__item" key={product.id}>
                  <div>
                    <h4>{product.name}</h4>
                    <p className="successDetails__item--variants">
                      {product.variants}
                    </p>
                    <p className="successDetails__item--variants">
                      <span>Qty: {product.qty}</span>
                    </p>
                    <p className="successDetails__item--total">
                      Total .............. <span>{product.total}</span>
                    </p>
                  </div>
                </div>
              )
            })}
            <div className="successDetails__totals">
              <h4>Order Totals</h4>
              <p>
                Sub-Total: <span>&#36;{sanitizedOrder.subtotal}</span>
              </p>
              {sanitizedOrder.gift_card_balanced_used !== 0 && (
                <p>
                  Gift Card:{" "}
                  <span>
                    &#8722; &#36;
                    {sanitizedOrder.gift_card_balanced_used_display}
                  </span>
                </p>
              )}
              <p>
                Tax: <span>&#36;{sanitizedOrder.taxes}</span>
              </p>
              <p>
                Shipping Cost: <span>&#36;{sanitizedOrder.shipping_cost}</span>
              </p>
              <p>
                Total: <span>&#36;{sanitizedOrder.total}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </OrderConfirmStyled>
  )
}

const OrderConfirmStyled = styled.div`
  width: 100%;
  padding: 7.5rem 0;

  @media print {
    margin: 0;
    padding: 0;
  }

  .wrapper {
    ${standardWrapper};

    @media print {
      width: 100%;
      max-width: 100%;
    }
  }

  .receipt {
    width: 100%;
    border: 0.1rem solid ${colors.black};
    background-color: ${colors.white};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    @media print {
      border: none;
      box-shadow: none;
    }
  }

  .successHeader {
    width: calc(100% - 4rem);
    margin: 2rem 2rem;
    padding: 2rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
      ${headlineThree};
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.5;

      @media print {
        font-size: 22px;
      }
    }

    p {
      ${bodyCopy};
      margin: 0;
      line-height: 1.5;

      @media print {
        font-size: 16px;
      }
    }
  }

  .successDelivery {
    width: calc(100% - 4rem);
    margin: 2rem;
    padding: 2rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    text-align: left;

    @media print {
      margin: 0;
      padding: 1rem;
    }

    h3 {
      ${headlineThree};
      font-weight: bold;
      margin: 0;

      @media print {
        font-size: 20px;
      }
    }

    p {
      ${bodyCopy};
      margin-bottom: 0.5rem;

      @media print {
        font-size: 16px;
      }
    }
  }

  .successDetails {
    width: calc(100% - 4rem);
    margin: 2rem 2rem;
    text-align: left;

    @media print {
      width: calc(100%);
      margin: 0;
      margin-top: 16px;
      padding: 1rem;
    }

    h3 {
      ${headlineThree};
      padding: 0 2rem;
      margin: 0;
      line-height: 1.5;
      font-weight: bold;

      @media print {
        font-size: 22px;
      }
    }

    &__item {
      padding: 2rem;
      margin-bottom: 3rem;
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);

      @media print {
        width: 100%;
        margin-bottom: 0;
      }

      h4 {
        ${bodyCopy};
        ${fontSizer(1.6, 2, 76.8, 150, 1.6)}
        margin: 0;
        color: ${colors.colorTertiary};

        @media print {
          font-size: 16px;
        }
      }

      p {
        ${bodyCopy};
        margin-bottom: 0.5rem;

        @media print {
          font-size: 16px;
        }
      }

      p.successDetails__item--variants {
        ${fontSizer(1.4, 1.6, 76.8, 150, 1.6)}
        color: #707070;
        font-style: italic;

        @media print {
          font-size: 16px;
        }
      }
      p.successDetails__item--total {
        ${fontSizer(1.4, 1.6, 76.8, 150, 1.6)}
        color: #707070;

        @media print {
          font-size: 16px;
        }

        span {
          font-weight: bold;
        }
      }
    }

    &__totals {
      padding: 2rem;
      @media print {
        margin: 0;
        padding: 0;
      }

      h4 {
        ${headlineThree};
        color: ${colors.colorPrimary};

        @media print {
          font-size: 22px;
        }
      }
      p {
        ${bodyCopy};
        margin-bottom: 0.5rem;

        @media print {
          font-size: 16px;
        }
      }
    }
  }
`

export default OrderConfirm
