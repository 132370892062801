import React, { useContext, useState, useEffect } from "react"
import queryString from "query-string"
import axios from "axios"
import { navigate } from "gatsby"
import styled from "styled-components"
import { UiContext } from "../../context/UiContext"
import { CartContext } from "../../context/CartContext"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import OrderConfirm from "../../components/success/OrderConfirm"
import { headlineThree, colors } from "../../Utilities"

const Success = (props) => {
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)
  const { clearCart, setCartTotal } = useContext(CartContext)
  const [successData, setSuccessData] = useState(null)

  useEffect(() => {
    uiDispatch({ type: "SERVER_LOAD" })
    // Get the token and payerId from the url query params sent by PayPal. //
    const queryData = queryString.parse(props.location.search)
    const successPayment = async (params) => {
      if (Object.keys(params).length === 0 && typeof params === "object") {
        uiDispatch({ type: "SERVER_LOAD_FINISHED" })
        navigate("/shop", { replace: true })
        return
      }
      try {
        const reponse = await axios.post(
          `${process.env.GATSBY_API_URL}/orders/paypal/success`,
          { params }
        )
        // Set the state with the response data from paypal. //
        setSuccessData(reponse.data)
        // Clear the cart. //
        clearCart()
        // Set the cart total back to zero. //
        setCartTotal(0)
        // Remove the query string from the url. //
        navigate("/checkout/success", { replace: true })
        // Remove the spinner from the UI. //
        uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      } catch (err) {
        // Remove the spinner from the UI. //
        uiDispatch({ type: "SERVER_LOAD_FINISHED" })
        // Alert the user of the error. //
        uiDispatch({
          type: "ALERT_ERROR",
          payload: `${err.message}, Please try again later.`,
        })
      }
    }
    successPayment(queryData)
  }, [])

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      <SuccessHeader>
        <h2>success</h2>
      </SuccessHeader>
      {successData && <OrderConfirm successData={successData} />}
    </Layout>
  )
}

const SuccessHeader = styled.div`
  padding: 1.5rem 3rem;
  background-color: ${colors.colorTertiary};
  text-align: center;

  h2 {
    ${headlineThree};
    color: ${colors.white};
    text-transform: uppercase;
  }

  @media print {
    display: none;
    margin: 0;
    padding: 0;
  }
`

export default Success
